.post-list {
  li {
    list-style-type: none;
  }
}
.post-container {
  width: 65%;
  margin: 0 auto;
}
.post-header {
  color: $text-color-dark;
  h1 {
    margin: 15px 0;
  }
  p {
    margin: 10px 0;
  }
}
.post-content {
  color: $text-color-dark;
  text-align: justify;
  h2 {
    margin: 15px 0;
  }
  p {
    margin: 25px 0;
    line-height: 1.4em;
    font-size: 1.05em;
  }
  a {
    color: $base-color;
    text-decoration: underline;
  }
}
@media only screen and (max-width: 1024px) {
  .post-container {
    width: 85%;
  }
}
@media only screen and (max-width: 480px) {
  .post-container {
    width: 100%;
  }
  .post-content {
    p {
      font-size: 1em;
      text-align: justify;
    }
  }
}
