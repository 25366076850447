//variables for colors
$base-color: #546E7A;
$base-highlight: #EEEEEE;
$base-color-medium: #E0E0E0;
$base-shadow: #616161;
$text-color-light: #F5F5F5;
$text-color-dark: #212121;
$text-color-standout: #FFEE58;

@import 'reset';
@import 'header';
@import 'footer';
@import 'index';
@import 'project_card';
@import 'post';
@import '404';

body {
  position: relative;
  min-height: 100vh;
  padding-bottom: 44px;
  font-family: sans-serif;
}
.show {
  display: block;
}
.content {
  width: 80%;
  padding: 30px 0;
  margin: 0 auto;
}
.page-title {
  margin: 15px 0
}
