header {
  height: 53px;
  line-height: 53px;
  padding: 0 15px;
  background: $base-color;
  a {
    font-size: 1.15em;
    color: $text-color-light;
  }
}
.header-bar {
  width: 80%;
  margin: 0 auto;
}
.dropdown {
  float: right;
}
.dropdown-btn {
  display: none;
  height: 33px;
  width: 53px;
  border: none;
  background-color: $base-highlight;
  color: $text-color-dark;
  font-size: 1.02em;
}
.navbar {
  list-style: none;
  li {
    display: inline-block;
    padding: 0 5px;
    a {
      line-height: 1.3em;
    }
  }
  li:hover {
    background: white;
    a {
      color: $text-color-dark;
    }
  }
}
header:after {
  content: "";
  display: table;
  clear: right;
}
.mobile-nav {
  display: block;
  position: absolute;
  right: 15px;
  width: 80px;
  background-color: $base-highlight;
  li {
    display: block;
    padding-left: 10px;
    a {
      color: $text-color-dark;
    }
  }
}
@media only screen and (max-width: 600px) {
  .dropdown-btn {
    display: inline-block;
  }
  .navbar {
    display: none;
  }
}
@media only screen and (max-width: 480px) {
  .header-bar {
    width: 100%;
  }
  header {
    a {
      font-size: 1.05em;
    }
  }
}
