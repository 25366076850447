.footer-bar {
  width: 80%;
  margin: 0 auto;
}
footer {
  position: absolute;
  bottom: 0;
  height: 53px;
  width: 100%;
  padding: 0 15px;
  background-color: $base-color;
  .footer-links {
    li {
      display: inline-block;
      padding: 16px 5px 0 5px;
      vertical-align: middle;
      a {
        color: $text-color-light;
        font-size: 1.1em;
      }
      img {
        max-height: 25px;
        max-width: 25px;
      }
    }
  }
}

@media only screen and (max-width: 480px) {
  .footer-bar {
    width: 100%;
  }
}
