.profile-card {
  width: 65%;
  margin: 0 auto;
  background-color: $base-color;
  box-shadow: 0 16px 33px -10px $base-shadow;
  color: $text-color-light;
  font-size: 1.1em;
  line-height: 1.6em;
  letter-spacing: 2px;
  border-radius: 16px;
}
.profile-body {
  display: flex;
  flex-wrap: wrap;
  padding: 25px;
}
.profile-header {
  p {
    padding: 15px 25px 0 25px;
    text-align: right;
    font-size: .8em;
  }
}
.profile-image {
  display: inline-block;
  width: 40%;
  img {
    width: 100%;
    border-radius: 16px;
  }
}
.profile-summary {
  display: inline-block;
  max-width: 60%;
  padding-left: 20px;
  p {
    padding-bottom: 15px;
    text-align: justify;
  }
  a {
    color: $text-color-standout;
    text-decoration: underline;
  }
}
.profile-footer {
  width: 100%;
  .social-links {
    float: right;
    padding-right: 25px;
    padding-bottom: 10px;
    li {
      display: inline;
      list-style: none;
      padding-left: 5px;
    }
    img {
      width: 30px;
      height: 30px;
    }
  }
}
.profile-footer:after {
  content: "";
  display: table;
  clear: right;
}
@media only screen and (max-width: 1100px) {
  .profile-card {
    width: 100%;
  }
}
@media only screen and (max-width: 720px) {
  .profile-body {
    display: inline-block;
  }
  .profile-image {
    width: 100%;
  }
  .profile-summary {
    max-width: 100%;
    padding-left: 0;
    padding-top: 25px;
  }
}
